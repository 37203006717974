// 获取时间段函数

export function getPeriodsTime() {
    let arr = [];
    for (let i = 7; i < 23; i++) {
        for (let j = 0; j < 2; j++) {
            let ele = i;
            if (j === 0) {
                ele = ele + ":00";
            } else {
                ele = ele + ":30";
            }
            ele = ele.padStart(5, "0");
            arr.push(ele);
        }
    }
    // console.log(arr.length);
    return arr;
}

export function getPeriodsTimeByDuration(duration) {
    if (duration === 2) {
        let arr = [];
        for (let i = 7; i < 23; i++) {
            let ele = i;
            ele = ele + ":00";
            ele = ele.padStart(5, "0");
            arr.push(ele);
        }
        // console.log(arr.length);
        return arr;
    } else {
        return getPeriodsTime()
    }

}

export function getWeek() {
    let arr = [];
    for (let i = 7; i < 23; i++) {
        let ele = i;
        ele = ele + ":00";
        ele = ele.padStart(5, "0");
        arr.push(ele);
    }
    // console.log(arr.length);
    return arr;
}

export function getFormatDate(value, sq) {
    const nowDate = new Date(value);
    const nowYear = nowDate.getFullYear();
    const nowMonth =
        nowDate.getMonth() + 1 < 10 ?
        "0" + (nowDate.getMonth() + 1) :
        nowDate.getMonth() + 1;
    const nowDay =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
    const nowHour =
        nowDate.getHours() < 10 ? "0" + nowDate.getHours() : nowDate.getHours();
    const nowMin =
        nowDate.getMinutes() < 10 ?
        "0" + nowDate.getMinutes() :
        nowDate.getMinutes();
    const nowSec =
        nowDate.getSeconds() < 10 ?
        "0" + nowDate.getSeconds() :
        nowDate.getSeconds();
    if (sq) {
        return (
            nowYear +
            sq +
            nowMonth +
            sq +
            nowDay +
            " " +
            nowHour +
            ":" +
            nowMin +
            ":" +
            nowSec
        );
    } else {
        return (
            nowYear +
            "-" +
            nowMonth +
            "-" +
            nowDay +
            " " +
            nowHour +
            ":" +
            nowMin +
            ":" +
            nowSec
        );
    }
}

import confetti from "canvas-confetti";

export function startConfetti() {
    var myCanvas = document.getElementById("confettiCanvas");
    var myConfetti = confetti.create(myCanvas, {
        resize: true,
        useWorker: true,
    });
    var end = Date.now() + 0.5 * 1000;

    // go Buckeyes!
    var colors = ["#ce4c3f", "#224e96", "#ffffff"];

    (function frame() {
        myConfetti({
            particleCount: 2,
            spread: 55,
            colors: colors,
        });

        if (Date.now() < end) {
            requestAnimationFrame(frame);
        }
    })();
}

export function playCorrectSound(iscorrect = true, finalSound = true) {
    const success_audio_sound_final = require("@/assets/audio/correct_answer.mp3");
    const success_audio_sound = require("@/assets/audio/correct.mp3");
    const wrong_audio_sound = require("@/assets/audio/wrong_answer.mp3");
    if (iscorrect === true) {
        if (finalSound == true) {
            var n = new Audio(success_audio_sound_final);
            n.src = success_audio_sound_final;
        } else {
            var n = new Audio(success_audio_sound);
            n.src = success_audio_sound;
        }
    } else if (iscorrect === false) {
        var n = new Audio(wrong_audio_sound);
        n.src = wrong_audio_sound;
    }
    n.play();
}



// 深拷贝
export function deepCopy(obj) {
    let result = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                result[key] = deepCopy(obj[key]); //递归复制
            } else {
                result[key] = obj[key];
            }
        }
    }
    return result;
};